<template>
  <div class="page">
    <img class="bg" src="@/assets/images/farming.png" alt="" />
    <div class="g-container" v-loading="loading">
      <div style="text-align: right;"
        ><el-button style="background-color: #fa541c;" type="danger" @click="onCreate">新增</el-button>
      </div>
      <div v-if="list.length === 0" class="g-empty"></div>
      <div class="g-py-20">
        <el-row :gutter="20">
          <el-col :span="12" v-for="item in list" :key="item.id" style="margin-bottom: 10px;">
            <div class="item" @click="onNav(item)">
              <i class="icon"></i>
              <div class="content g-text-ellipsis">{{ item.title }}</div>
              <i class="right"></i>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <form-view ref="form" @success="fetchList"></form-view>
  </div>
</template>

<script>
import FormView from './form.vue'
import { getMenuPage } from '@/api/farming'

export default {
  components: {
    FormView,
  },
  data() {
    return {
      loading: false,
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.loading = true
      const params = {
        examine_status: 'pass',
      }
      getMenuPage(params)
        .then(res => {
          this.list = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    onNav(record) {
      this.$router.push({ path: '/farming/detail/index', query: { id: record.id } })
    },
    onCreate() {
      this.$refs.form.open({
        menu_id: '',
        content: '',
        source: '',
        date: '',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  overflow: hidden auto;
  background-color: #ffe2d7;
}

.bg {
  width: 100%;
  height: auto;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  color: #1f1f1f;
  background-color: #fff;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }

  .icon {
    width: 20px;
    height: 24px;
    background: url('~@/assets/icons/farming-icon.png') center / 100% no-repeat;
  }

  .right {
    width: 20px;
    height: 20px;
    background: url('~@/assets/icons/farming-right.png') center / 100% no-repeat;
  }

  .content {
    flex: 1;
    min-width: 0;
    padding: 0 10px;
  }
}
</style>
